/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */

export const SESSION_USER = "SESSION_USER";
export const SESSION_USER_SUCCESS = "SESSION_USER_SUCCESS";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const GOOGLE_LOGIN = "GOOGLE_LOGIN";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_GETTOKEN = "GOOGLE_GETTOKEN";
export const GOOGLE_GETTOKEN_SUCCESS = "GOOGLE_GETTOKEN_SUCCESS";
export const FACEBOOK_LOGIN = "FACEBOOK_LOGIN";
export const FACEBOOK_GETTOKEN = "FACEBOOK_GETTOKEN";
export const FACEBOOK_LOGIN_SUCCESS = "FACEBOOK_LOGIN_SUCCESS";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

export const GET_COACHES = "GET_COACHES";
export const GET_COACHES_SUCCESS = "GET_COACHES_SUCCESS";


/* Users */
//Get basic user data by ID
export const USERDATA = "USERDATA";
//Get advanced user data by ID
export const ADVANCED_USER_DATA = "ADVANCED_USER_DATA";
export const ADVANCED_USER_DATA_SUCCESS = "ADVANCED_USER_DATA_SUCCESS";
//

//Save Avatar
export const SAVE_AVATAR = "SAVE_AVATAR";
export const SAVE_AVATAR_SUCCES = "SAVE_AVATAR_SUCCES";
// Save any field on students or coaches
export const SAVE_FIELD = "SAVE_FIELD";
export const SAVE_FIELD_SUCCESS = "SAVE_FIELD_SUCCESS";
/* end users */


/* productos on dashboard folder*/
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";

/* Get Categories and subcategories */
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";


/* UploadVideo */
export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";

/* save Product */
export const SAVE_PRODUCT = "SAVE_PRODUCT";
export const SAVE_PRODUCT_SUCCESS = "SAVE_PRODUCT_SUCCESS";

/* get Product */
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";

export const PRODUCT_SELECTED = "PRODUCT_SELECTED";

/* get Education from coach */
export const GET_EDUCATION = "GET_EDUCATION";
export const GET_EDUCATION_SUCCESS = "GET_EDUCATION_SUCCESS";

/* get expericence from coach */
export const GET_EXPERIENCE = "GET_EXPERIENCE";
export const GET_EXPERIENCE_SUCCESS = "GET_EXPERIENCE_SUCCESS";

/* get testimonies from coach */
export const GET_TESTIMONIES = "GET_TESTIMONIES";
export const GET_TESTIMONIES_SUCCESS = "GET_TESTIMONIES_SUCCESS";

/* get testimonies from module or product */
export const GET_TESTIMONIES_MODULE = "GET_TESTIMONIES_MODULE";
export const GET_TESTIMONIES_MODULE_SUCCESS = "GET_TESTIMONIES_MODULE_SUCCESS";


/* DASHBOARD PRODUCTS */
export const SET_MODULE_VIDEOURLS = "SET_MODULE_VIDEOURLS";

/* DASHBOARD PRODUCTS */
export const SET_MODULE_IMAGES_DOCUMENTS = "SET_MODULE_IMAGES_DOCUMENTS";

/* GET GENERIC STATES POSIBLE OF PRODUCTS  */
export const GET_PRODUCT_STATES = "GET_PRODUCT_STATES";
export const GET_PRODUCT_STATES_SUCCESS = "GET_PRODUCT_STATES_SUCCESS";

/* UPDATE PROFILE PICTURE */
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";

export const DESTROY_SESSION = "DESTROY_SESSION";


/* 
CHECK REAL TIME PROCCESSING VIDEOS 
ARRAY WITH VIDEOS READY 
*/
export const VIDEOS_READY = "VIDEOS_READY";
        

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./dashboard/actions";
