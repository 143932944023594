import {
        UPDATE_PASSWORD,
        UPDATE_PASSWORD_SUCCESS,
        GET_CATEGORIES,
        GET_CATEGORIES_SUCCESS,
        UPLOAD_FILE,
        UPLOAD_FILE_SUCCESS,
        SAVE_PRODUCT,
        SAVE_PRODUCT_SUCCESS,
        GET_PRODUCTS,
        GET_PRODUCTS_SUCCESS,
        PRODUCT_SELECTED,
        GET_EDUCATION,
        GET_EDUCATION_SUCCESS,
        GET_EXPERIENCE,
        GET_EXPERIENCE_SUCCESS,
        GET_TESTIMONIES,
        GET_TESTIMONIES_SUCCESS,
        SET_MODULE_VIDEOURLS,
        SET_MODULE_IMAGES_DOCUMENTS,
        GET_TESTIMONIES_MODULE,
        GET_TESTIMONIES_MODULE_SUCCESS,
        GET_PRODUCT_STATES,
        GET_PRODUCT_STATES_SUCCESS,
        VIDEOS_READY,
} from '../actions';

export const updatePassword = (data) => ({
        type: UPDATE_PASSWORD,
        payload: data
});
export const updatePasswordSuccess = (data) => ({
        type: UPDATE_PASSWORD_SUCCESS,
        payload: data
});
export const getCategories = (data) => ({
        type: GET_CATEGORIES,
        payload: data
});
export const getCategoriesSuccess = (data) => ({
        type: GET_CATEGORIES_SUCCESS,
        payload: data
});
export const uploadFile = (data) => ({
        type: UPLOAD_FILE,
        payload: data,
});
export const uploadFileSuccess = (data) => ({
        type: UPLOAD_FILE_SUCCESS,
        payload: data
});
export const saveProduct = (data) => ({
        type: SAVE_PRODUCT,
        payload: data,
});
export const saveProductSucces = (data) => ({
        type: SAVE_PRODUCT_SUCCESS,
        payload: data
});
export const getProducts = (data) => ({
        type: GET_PRODUCTS,
        payload: data,
});
export const getProductsSucces = (data) => ({
        type: GET_PRODUCTS_SUCCESS,
        payload: data
});
export const productSelecte = (data) => ({
        type: PRODUCT_SELECTED,
        payload: data
});
export const getEducation = (data) => ({
        type: GET_EDUCATION,
        payload: data
});
export const getEducationSuccess = (data) => ({
        type: GET_EDUCATION_SUCCESS,
        payload: data
});
export const getExperience = (data) => ({
        type: GET_EXPERIENCE,
        payload: data
});
export const getExperienceSuccess = (data) => ({
        type: GET_EXPERIENCE_SUCCESS,
        payload: data
});
export const getTestimonies = (data) => ({
        type: GET_TESTIMONIES,
        payload: data
});
export const getTestimoniesSuccess = (data) => ({
        type: GET_TESTIMONIES_SUCCESS,
        payload: data
});
/* get testimonies of a product/module */
export const getTestimoniesModule = (data) => ({
        type: GET_TESTIMONIES_MODULE,
        payload: data
});
export const getTestimoniesModuleSuccess = (data) => ({
        type: GET_TESTIMONIES_MODULE_SUCCESS,
        payload: data
});

/* dashboard prods no saga */
export const setModuleVideoURLs = (data) => ({
        type: SET_MODULE_VIDEOURLS,
        payload: data
});
/* dashboard images no saga */
export const setModuleImagesURLs = (data) => ({
        type: SET_MODULE_IMAGES_DOCUMENTS,
        payload: data
});
/* get generic prods states */
export const getProductStates = (data) => ({
        type: GET_PRODUCT_STATES,
        payload: data
});
export const getProductStatesSuccess = (data) => ({
        type: GET_PRODUCT_STATES_SUCCESS,
        payload: data
});
export const videosReady = (data) => ({
        type: VIDEOS_READY,
        payload: data
});