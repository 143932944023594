import {
  SESSION_USER,
  SESSION_USER_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  GET_COACHES,
  GET_COACHES_SUCCESS,
  GOOGLE_LOGIN,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_GETTOKEN,
  GOOGLE_GETTOKEN_SUCCESS,
  FACEBOOK_LOGIN,
  FACEBOOK_LOGIN_SUCCESS,
  FACEBOOK_GETTOKEN,
  USERDATA,
  ADVANCED_USER_DATA,
  ADVANCED_USER_DATA_SUCCESS,
  SAVE_AVATAR,
  SAVE_AVATAR_SUCCES,
  SAVE_FIELD,
  DESTROY_SESSION,
  SAVE_FIELD_SUCCESS, UPDATE_PROFILE, UPDATE_PROFILE_SUCCESS
} from '../actions';


export const sessionUser = (user) => ({
  type: SESSION_USER,
  payload: user
});
export const sessionUserSuccess = (user) => ({
  type: SESSION_USER_SUCCESS,
  payload: user
});

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history }
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history }
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message }
});

export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode, newPassword, history }
});
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message }
});



export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history }
})
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
})
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message }
})

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history }
});

export const getCoaches = (coaches) => ({
  type: GET_COACHES,
  payload: { coaches }
});
export const getCoachesSuccess = (coaches) => ({
  type: GET_COACHES_SUCCESS,
  payload: coaches
});
export const loginGoogle = (user) => ({
  type: GOOGLE_LOGIN,
  payload: user
});
export const loginGoogleSucces = (user) => ({
  type: GOOGLE_LOGIN_SUCCESS,
  payload: user
});
export const googleGetToken = (loadingToken) => ({
  type: GOOGLE_GETTOKEN,
  payload: { loadingToken }
});
export const googleGetTokenSuccess = (loadingToken, user) => ({
  type: GOOGLE_GETTOKEN_SUCCESS,
  payload: { loadingToken, user }
});
export const loginFacebook = (user) => ({
  type: FACEBOOK_LOGIN,
  payload: user
});
export const loginFacebookSucces = (user) => ({
  type: FACEBOOK_LOGIN_SUCCESS,
  payload: user
});
export const facebookGetToken = (token) => ({
  type: FACEBOOK_GETTOKEN,
  payload: token
});
//Get local storage User Data, saved previusly from login 
export const getUserMainData = (data) => ({
  type: USERDATA,
  payload: data
});

//Get advanced user Data and save it on localstorage, it's called from stat view
export const getAdvancedUserData = (data) => ({
  type: ADVANCED_USER_DATA,
  payload: data
});
export const getAdvancedUserDataSuccess = (data) => ({
  type: ADVANCED_USER_DATA_SUCCESS,
  payload: data
});

//SAVE AVATAR FIRESTORE
export const saveAvatar = (data) => ({
  type: SAVE_AVATAR,
  payload: data,
});
export const saveAvatarSucces = (data) => ({
  type: SAVE_AVATAR_SUCCES,
  payload: data,
});
//SAVE PICTURE FIRESTORE
export const updateProfilePicture = (data) => ({
  type: UPDATE_PROFILE,
  payload: data,
});
export const updateProfilePictureSucces = (data) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: data,
});

//SAVE ANY FIELD ON STUDENTS OR COACHES TABLE
export const saveField = (data) => ({
  type: SAVE_FIELD,
  payload: data,
});
export const saveFieldSucess = (data) => ({
  type: SAVE_FIELD_SUCCESS,
  payload: data,
});
export const destroySession = (data) => ({
  type: DESTROY_SESSION,
  payload: data,
});