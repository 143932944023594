import {
    SESSION_USER,
    SESSION_USER_SUCCESS,
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    GET_COACHES,
    GET_COACHES_SUCCESS,
    GOOGLE_LOGIN,
    GOOGLE_LOGIN_SUCCESS,
    FACEBOOK_LOGIN,
    FACEBOOK_LOGIN_SUCCESS,
    FACEBOOK_GETTOKEN,
    GOOGLE_GETTOKEN,
    GOOGLE_GETTOKEN_SUCCESS,
    USERDATA,
    ADVANCED_USER_DATA,
    ADVANCED_USER_DATA_SUCCESS,
    SAVE_AVATAR,
    SAVE_AVATAR_SUCCES,
    SAVE_FIELD_SUCCESS,
    SAVE_FIELD,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE,
    DESTROY_SESSION,
} from '../actions';
import { auth, _firebase } from '../../helpers/Firebase';
const INIT_STATE = {
    adUserData: {},
    userData: [],
    user: null,
    loadingSession: false,
    forgotUserMail: '',
    newPassword: '',
    resetPasswordCode: '',
    loading: false,
    error: '',
    coaches: [],
    info: '',
    isLogin: false,
    loadingToken: false,
    avatar: null,
    loadingSaveAvatar: false,
    profilePicture: null,
    loadingProfilePicture: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SESSION_USER:
            return { ...state, loadingUser: true, error: '' };
        case SESSION_USER_SUCCESS:
            return { ...state, loadingUser: false, user: action.payload, error: '' };
        case LOGIN_USER:
            return { ...state, loading: true, error: '' };
        case LOGIN_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload.uid, error: '' };
        case LOGIN_USER_ERROR:
            return { ...state, loading: false, user: '', error: action.payload.message };
        case FORGOT_PASSWORD:
            return { ...state, loading: true, error: '' };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false, forgotUserMail: action.payload, error: '' };
        case FORGOT_PASSWORD_ERROR:
            return { ...state, loading: false, forgotUserMail: '', error: action.payload.message };
        case RESET_PASSWORD:
            return { ...state, loading: true, error: '' };
        case RESET_PASSWORD_SUCCESS:
            return { ...state, loading: false, newPassword: action.payload, resetPasswordCode: '', error: '' };
        case RESET_PASSWORD_ERROR:
            return { ...state, loading: false, newPassword: '', resetPasswordCode: '', error: action.payload.message };
        case REGISTER_USER:
            return { ...state, loading: true, error: '' };
        case REGISTER_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload.uid, error: '', isLogin: false };
        case REGISTER_USER_ERROR:
            return { ...state, loading: false, user: '', error: action.payload.message };
        case LOGOUT_USER:
            return { ...state, user: null, error: '' };
        case GET_COACHES:
            return { ...state, coaches: action.payload, error: '' };
        case GET_COACHES_SUCCESS:
            return { ...state, coaches: action.payload, error: '' };
        case GOOGLE_LOGIN:
            return { ...state, error: '', user: action.payload };
        case GOOGLE_LOGIN_SUCCESS:
            return { ...state, user: action.payload, isLogin: true, loading: false, error: '' };
        case GOOGLE_GETTOKEN:
            return { ...state, error: '', loadingToken: action.payload.loadingToken.t };
        case GOOGLE_GETTOKEN_SUCCESS:
            return { ...state, error: '', loadingToken: action.payload.loadingToken, user: action.payload.user, isLogin: true };
        case FACEBOOK_LOGIN:
            return { ...state, error: '', user: action.payload };
        case FACEBOOK_LOGIN_SUCCESS:
            return { ...state, user: action.payload, isLogin: true, loading: false, error: '' };
        case FACEBOOK_GETTOKEN:
            return { ...state, user: action.payload, isLogin: false, loading: false, error: '' };
        case USERDATA:
            return { ...state, userData: action.payload };
        case ADVANCED_USER_DATA:
            return { ...state };
        case ADVANCED_USER_DATA_SUCCESS:
            return { ...state, adUserData: action.payload };
        case SAVE_AVATAR:
            return { ...state, loadingSaveAvatar: true };
        case SAVE_AVATAR_SUCCES:
            return { ...state, avatar: action.payload, loadingSaveAvatar: false };
        case UPDATE_PROFILE:
            return { ...state, loadingProfilePicture: true };
        case UPDATE_PROFILE_SUCCESS:
            return { ...state, profilePicture: action.payload, loadingProfilePicture: false };
        case SAVE_FIELD:
            return { ...state, loading: false };
        case SAVE_FIELD_SUCCESS:
            return { ...state, loading: action.payload };
        case DESTROY_SESSION:
            state = { ...INIT_STATE }
            return { ...state };
        default: return { ...state };
    }


}
