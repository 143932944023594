
import { all, call, fork, put, takeEvery, take } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import { auth, firestore, googleProvider, facebookProvider, storage } from '../../helpers/Firebase';
import {
    SESSION_USER,
    LOGIN_USER,
    REGISTER_USER,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    GET_COACHES,
    GOOGLE_LOGIN,
    GOOGLE_GETTOKEN,
    FACEBOOK_LOGIN,
    ADVANCED_USER_DATA,
    SAVE_AVATAR,
    SAVE_FIELD,
    USERDATA,
    UPDATE_PROFILE
} from '../actions';

import {
    updateProfilePictureSucces,
    sessionUserSuccess,
    saveFieldSucess,
    saveAvatarSucces,
    getAdvancedUserDataSuccess,
    getCoachesSuccess,
    googleGetTokenSuccess,
    loginUserSuccess,
    loginUserError,
    registerUserSuccess,
    registerUserError,
    forgotPasswordSuccess,
    forgotPasswordError,
    resetPasswordSuccess,
    resetPasswordError,
    getUserMainData,
} from './actions';
import { array } from 'yup';
/* 
CHECK SESSION USER
*/
export function* watchSessionUser() {
    yield takeEvery(SESSION_USER, getSessionUser);
}
export function* getSessionUser({ payload }) {

    try {
        const channel = yield call(getAuthChannel);
        const result = yield take(channel);
        if (result) {
            yield put(getUserMainData(result.user));
            yield put(sessionUserSuccess(result.user ? result.user.uid : null));

        }
    } catch (error) {
        console.error(error.message);
    }
}
var authChannel = null;
function getAuthChannel() {
    if (!authChannel) {
        authChannel = eventChannel(emit => {
            const unsubscribe = auth.onAuthStateChanged(user => emit({ user }));
            return unsubscribe;
        });
    }
    return authChannel;
}
/*
END USER DATA ----------------- 
 */
/*
 SAVE CUSTOM FIELD
 */

export function* watchSaveField() {
    yield takeEvery(SAVE_FIELD, saveFieldFirestore);
}
export function* saveFieldFirestore({ payload }) {
    let { data, uid } = payload;
    try {
        let type = localStorage.getItem('type');
        console.log(data, uid, type);
        const success = yield call(saveFieldAsync, type, data, uid);
        const user = yield call(getAdvancedUserDataFirestoreAsync, uid, type);
        if (!user.message) {
            yield put(getAdvancedUserDataSuccess(user.data()));
        }
    } catch (error) {
        console.error(error.message);
    }
}
const saveFieldAsync = async (collection, data, uid) =>
    await firestore.collection(collection).doc(uid).update(data)
        .then(result => result)
        .catch(error => error);
/*
 SAVE AVATAR
 */
export function* watchSaveAvatar() {
    yield takeEvery(SAVE_AVATAR, saveAvatarFirestore);
}
function* saveAvatarFirestore({ payload }) {
    let { preview, userID } = payload;
    try {
        let ref = storage.ref();
        let folderRef = ref.child(userID + '/avatar.png');
        const success = yield call(saveAvatarFirestoreAsync, preview, folderRef);
        if (success.state === 'success') {
            try {
                let url = yield call([folderRef, folderRef.getDownloadURL]);
                let update = yield call(updatePhoto, auth.currentUser, url);
                if (!update.message) {
                    yield put(saveAvatarSucces(url));
                }
            } catch (error) {
                console.error(error.message);
            }
        }
    } catch (error) {
        console.error(error.message);
    }

}
const saveAvatarFirestoreAsync = async (base, ref) =>
    await ref.putString(base, 'data_url', { contentType: 'image/png' })
        .then(doc => doc)
        .catch(error => error);

const updatePhoto = async (user, photoURL) =>
    await user.updateProfile({
        photoURL
    })
        .then(() => user)
        .catch(error => error);
/*
 SAVE PROFILE PICTURE
 */
export function* watchUpdateProfile() {
    yield takeEvery(UPDATE_PROFILE, updateProfilePicure);
}
function* updateProfilePicure({ payload }) {
    let { url, userID } = payload;
    try {
        const success = yield call(updateProfilePicureAsync, url, userID);
        yield put(updateProfilePictureSucces(url));
    } catch (error) {
        console.error(error.message);
    }
}
const updateProfilePicureAsync = async (url, doc) =>
    firestore.collection('coaches').doc(doc).update({
        picture: url,
    }).then(e => e)
        .catch(error => error);
/* 
  Get advanced user Data 
*/
export function* watchGetAdvancedUserData() {
    yield takeEvery(ADVANCED_USER_DATA, getAdvancedUserDataFirestore);
}
function* getAdvancedUserDataFirestore({ payload }) {
    let arr = [];
    try {
        let type = localStorage.getItem('type');
        const success = yield call(getAdvancedUserDataFirestoreAsync, payload, type);
        if (!success.message) {
            yield put(getAdvancedUserDataSuccess(success.data()));
        }
    } catch (error) {
        console.error(error.message);
    }
}
const getAdvancedUserDataFirestoreAsync = async (doc, coll) =>
    await firestore.collection(coll).doc(doc).get()
        .then(doc => doc)
        .catch(error => error);
/*
   end get advanced user data
 */
/* get coaches script */
export function* watchGetCoaches() {
    yield takeEvery(GET_COACHES, getCoachesFirestore);
}
function* getCoachesFirestore({ payload }) {
    let arr = [];
    try {
        const success = yield call(getCoachesFirestoreAsync);
        if (!success.message) {
            success.forEach(doc => {
                let data = doc.data();
                data.userID = doc.id;
                arr.push(data);
            })
            const order = yield call(getOrderCoaches);
            let _a1 = [];
            let _a2 = [];
            if (!order.message) {
                order.forEach(doc => {
                    let data = doc.data();
                    arr.forEach((element, i) => {
                        if (element.email === data.email) {
                            _a2[parseInt(data.position)] = element;
                            delete arr[i];
                        }
                    })
                });
                _a2.reverse();
                _a2.forEach(element => {
                    arr.unshift(element);
                });
            }
            yield put(getCoachesSuccess(arr));
        }
    } catch (error) {
        // yield put(loginUserError(error));

    }
}

const getOrderCoaches = async () =>
    await firestore.collection('order-coaches').get()
        .then(snapshot => snapshot)
        .catch(error => error);
const getCoachesFirestoreAsync = async () =>
    await firestore.collection('coaches').get()
        .then(snapshot => snapshot)
        .catch(error => error);
/* end */
///////////////////
/* Google y facebook log in */
///////////////////
export function* watchGoogleLogin() {
    yield takeEvery(GOOGLE_LOGIN, googleLogin);
}
export function* watchFacebookLogin() {
    yield takeEvery(FACEBOOK_LOGIN, facebookLogin);
}
/* funciona para ambos google y facebook */
export function* watchGoogleGetToken() {
    yield takeEvery(GOOGLE_GETTOKEN, googleToken);
}
function* googleToken({ payload }) {
    let { collection, loadingToken } = payload
    try {
        const success = yield call(googleTokenAsync);
        if (!success.message && success.user && !success.additionalUserInfo.isNewUser) {
            if (success.additionalUserInfo.isNewUser) {
                return;
            }
            let coll = collection ? collection : 'students';
            var advData = yield call(getAdvancedUserDataFirestoreAsync, success.user.uid, coll);
            if (advData.data()) {
                collection = 'students';
            } else {
                advData = yield call(getAdvancedUserDataFirestoreAsync, success.user.uid, 'coaches');
                if (advData.data()) {
                    collection = 'coaches';
                }
            }
            yield put(getAdvancedUserDataSuccess(advData.data()));
            let _obj = advData.data();
            delete _obj.confirmPassword;
            localStorage.setItem('user_id', success.user.uid);
            localStorage.setItem('userData', JSON.stringify(success.user));
            localStorage.setItem('userData2', JSON.stringify(_obj));
            localStorage.setItem('type', collection);
            yield put(googleGetTokenSuccess(false, success.user.uid));
            loadingToken.history.push('/app/home/start');
        } else {
            yield put(googleGetTokenSuccess(false, null));
        }
    } catch (error) {

    }
}
function* googleLogin({ payload }) {
    try {
        const success = yield call(googleLoginAsync);

    } catch (error) {

    }
}
function* facebookLogin({ payload }) {
    //console.log(payload);
    try {
        const success = yield call(facebookLoginAsync);

    } catch (error) {

    }
}
const googleLoginAsync = async () =>
    await auth.signInWithRedirect(googleProvider)
        .then(result => result)
        .catch(error => error);
const facebookLoginAsync = async () =>
    await auth.signInWithRedirect(facebookProvider)
        .then(result => result)
        .catch(error => error);

const googleTokenAsync = async () =>
    await auth.getRedirectResult()
        .then(result => result)
        .catch(error => error);
/* end */

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
    await auth.signInWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
        if (!loginUser.message) {
            localStorage.setItem('user_id', loginUser.user.uid);
            let collection = '';
            var advData = yield call(getAdvancedUserDataFirestoreAsync, loginUser.user.uid, 'students');
            if (advData.data()) {
                collection = 'students';
            } else {
                advData = yield call(getAdvancedUserDataFirestoreAsync, loginUser.user.uid, 'coaches');
                console.log(advData.data());
                if (advData.data()) {
                    collection = 'coaches';
                }
            }
            yield put(getAdvancedUserDataSuccess(advData.data()));
            let _obj = advData.data();
            delete _obj.confirmPassword;
            //  localStorage.setItem('user_id', loginUser.user.uid);
            /*  localStorage.setItem('userData', JSON.stringify(loginUser.user));
             localStorage.setItem('userData2', JSON.stringify(_obj)); */
            localStorage.setItem('type', collection);
            // yield put(googleGetTokenSuccess(false, loginUser.user.uid));
            yield put(getUserMainData(loginUser.user));
            yield put(loginUserSuccess(loginUser.user));
            // history.push('/app/home/start');
            history.push('/');
        } else {
            yield put(loginUserError(loginUser.message));
        }
    } catch (error) {
        yield put(loginUserError(error));

    }
}


export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}
const updateProfileAsync = async (userName, photoURL, user) =>
    await user.updateProfile({
        displayName: userName,
        photoURL
    })
        .then(() => user)
        .catch(error => error);

const registerWithEmailPasswordAsync = async (email, password) =>
    await auth.createUserWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

const saveData = async (data, collection, doc) =>
    await firestore.collection(collection).doc(doc).set(data)
        .then(result => new Object())
        .catch(error => error);

export function* registerWithEmailPassword({ payload }) {
    const { email, password, type, name, picture } = payload.user;

    let data = { ...payload.user };
    delete data.password;
    delete data.confirmPassword;
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, email, password);
        if (!registerUser.message) {
            // localStorage.setItem('user_id', registerUser.user.uid);
            let collection = type === 'coach' ? 'coaches' : 'students';
            let updatedUser = yield updateProfileAsync(name, picture, auth.currentUser);
            let ss = yield call(saveData, data, collection, registerUser.user.uid);
            if (!ss.message) {
                //  localStorage.setItem('user_id', registerUser.user.uid);
                //  localStorage.setItem('userData', JSON.stringify(registerUser.user));
                localStorage.setItem('type', collection);
                yield put(getUserMainData(registerUser.user));
                yield put(registerUserSuccess(registerUser.user));
            } else {
                console.log(!ss.message);
            }

            //history.push('/')
        } else {
            yield put(registerUserError(registerUser.message));

        }
    } catch (error) {
        console.error(error.message);    }
}



export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
    await auth.signOut().then(authUser => authUser).catch(error => error);
    history.push('/');
    window.location.reload();
}

function* logout({ payload }) {
    const { history } = payload
    try {
        yield call(logoutAsync, history);
        localStorage.removeItem('user_id');
        localStorage.removeItem('userData');
    } catch (error) {
    }
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
    return await auth.sendPasswordResetEmail(email)
        .then(user => user)
        .catch(error => error);
}

function* forgotPassword({ payload }) {
    const { email } = payload.forgotUserMail;
    try {
        const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
        if (!forgotPasswordStatus) {
            yield put(forgotPasswordSuccess("success"));
        } else {
            yield put(forgotPasswordError(forgotPasswordStatus.message));
        }
    } catch (error) {
        yield put(forgotPasswordError(error));

    }
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
    return await auth.confirmPasswordReset(resetPasswordCode, newPassword)
        .then(user => user)
        .catch(error => error);
}

function* resetPassword({ payload }) {
    const { newPassword, resetPasswordCode } = payload;
    try {
        const resetPasswordStatus = yield call(resetPasswordAsync, resetPasswordCode, newPassword);
        if (!resetPasswordStatus) {
            yield put(resetPasswordSuccess("success"));
        } else {
            yield put(resetPasswordError(resetPasswordStatus.message));
        }
    } catch (error) {
        yield put(resetPasswordError(error));

    }
}

export default function* rootSaga() {
    yield all([
        fork(watchUpdateProfile),
        fork(watchSessionUser),
        fork(watchSaveField),
        fork(watchSaveAvatar),
        fork(watchGetAdvancedUserData),
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgotPassword),
        fork(watchResetPassword),
        fork(watchGetCoaches),
        fork(watchGoogleLogin),
        fork(watchFacebookLogin),
        fork(watchGoogleGetToken),
    ]);
}