
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth, firestore, storage } from '../../helpers/Firebase';
import {
        UPDATE_PASSWORD,
        GET_CATEGORIES,
        UPLOAD_FILE,
        SAVE_PRODUCT,
        GET_PRODUCTS,
        GET_EDUCATION,
        GET_EXPERIENCE,
        GET_TESTIMONIES,
        GET_TESTIMONIES_MODULE,
        GET_PRODUCT_STATES,
} from '../actions';
import {
        updatePasswordSuccess,
        getCategoriesSuccess,
        uploadFileSuccess,
        saveProductSucces,
        getProductsSucces,
        getEducationSuccess,
        getExperienceSuccess,
        getTestimoniesSuccess,
        getTestimoniesModuleSuccess,
        getProductStatesSuccess,
} from './actions';

/* 
GET PRODUCT STATES 
*/
export function* whatchGetProductStates() {
        yield takeEvery(GET_PRODUCT_STATES, getProductStatesFirestore);
}
function* getProductStatesFirestore() {
        let states = yield call(getProductStatesAsync);
        let arr = [];
        if (!states.message) {
                states.forEach(doc => {
                        arr[doc.id] = doc.data();
                })
        }
        yield put(getProductStatesSuccess(arr));
}
const getProductStatesAsync = async () => {
        return await firestore.collection('product-states').get()
                .then(data => data)
                .catch(error => error);

}
/* END GET PRODUCT STATES */
/* 
GET TESTIMONIES MODULE OR PRODUCT
*/
export function* watchGetTestimoniesModule() {
        yield takeEvery(GET_TESTIMONIES_MODULE, getTestimoniesModuleFirestore);
}
function* getTestimoniesModuleFirestore({ payload }) {
        let success = yield call(getTestimoniesModuleAsync, payload.user, 'modules');
        if (!success.message) {
                yield put(getTestimoniesModuleSuccess(success.data()));
        }
}
const getTestimoniesModuleAsync = async (uid, collection) => {
        return await firestore.collection(collection).where('uid', '==', uid).get()
                .then(data => data)
                .catch(error => error);

}
/* 
END ------------
*/
/* 
GET TESTIMONIES
*/
export function* watchGetTestimonies() {
        yield takeEvery(GET_TESTIMONIES, getTestimoniesFirestore);
}
function* getTestimoniesFirestore({ payload }) {
       // console.log(payload)
        let success = yield call(getTestimoniesAsync, payload.user || payload, 'profile-testimonies');
        if (!success.message) {
                yield put(getTestimoniesSuccess(success.data()));
        }
}
const getTestimoniesAsync = async (uid, collection) => {
        return await firestore.collection(collection).doc(uid).get()
                .then(data => data)
                .catch(error => error);
}
/* 
END ------------
*/
/* 
GET EDUCATION
*/
export function* watchGetEducation() {
        yield takeEvery(GET_EDUCATION, getEducationFirestore);
}
function* getEducationFirestore({ payload }) {
        let uid = payload;//localStorage.getItem('user_id');
        let success = yield call(getEducationAsync, uid);
        if (!success.message) {
                yield put(getEducationSuccess(success.data()));
        }
}
const getEducationAsync = async (uid) => {
        return await firestore.collection('education').doc(uid).get()
                .then(data => data)
                .catch(error => error);
}
/* 
END ------------
*/
/* 
GET EXPERIENCE
*/
export function* watchGetExperience() {
        yield takeEvery(GET_EXPERIENCE, getExperienceFirestore);
}
function* getExperienceFirestore({ payload }) {
        let uid = payload;//localStorage.getItem('user_id');
        let success = yield call(getExperienceAsync, uid);
        if (!success.message) {
                yield put(getExperienceSuccess(success.data()));
        }
}
const getExperienceAsync = async (uid) => {
        return await firestore.collection('experience').doc(uid).get()
                .then(data => data)
                .catch(error => error);
}
/* 
END ------------
*/
/* 
GET PRODUCTS
*/
export function* watchGetProducts() {
        yield takeEvery(GET_PRODUCTS, getProductsFirestore);
}
function* getProductsFirestore({ payload }) {
        let uid = payload;//localStorage.getItem('user_id');
        let success = yield call(getProductsAsync, uid);
        if (!success.message) {
                let arr = [];
                success.forEach(function (doc) {
                        // doc.data() is never undefined for query doc snapshots
                        let data = doc.data();
                        data.id = doc.id;

                        arr.push(data);
                });
                yield put(getProductsSucces(arr));
        }
}
const getProductsAsync = async (uid) => {
        return await firestore.collection('modules').where('uid', '==', uid).get()
                .then(data => data)
                .catch(error => error);
}
/* 
SAVE PRODUCT
*/
export function* watchSaveProduct() {
        yield takeEvery(SAVE_PRODUCT, uploadProductData);
}
const randomID = (length = 30) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
}
function* uploadProductData({ payload }) {
        let id = randomID();
        let success = yield call(uploadFileAsync, payload, id);
        if (!success.message) {
                let _payload = {...payload, prodID:id}
                yield put(saveProductSucces(_payload));
        }
}
const uploadFileAsync = async (data, id) => {
        return await firestore.collection('modules').doc(id).set(data)
                .then(() => true)
                .catch(error => error);
}
/*  
UPLOAD FILE START
*/
export function* watchUploadFile() {
        yield takeEvery(UPLOAD_FILE, uploadFileFirestore);
}
function* uploadFileFirestore({ payload }) {
        let userID = payload.uid; //localStorage.getItem('user_id');
        var storageRef = storage.ref(`${userID}/${payload.name}`);
        var uploadTask = storageRef.put(payload);
        let obj = {
                storageRef,
                uploadTask
        }
        yield put(uploadFileSuccess(obj));
        /*  uploadTask.on('state_changed', function progress(snapshot) {
                 console.log(snapshot.totalBytesTransferred); // progress of upload
         }); */
}

/* 
UPLOAD FILE END
*/
/* 
update password section START
*/
export function* watchUpdatePassword() {
        yield takeEvery(UPDATE_PASSWORD, updatePasswordFirebase);
}
function* updatePasswordFirebase({ payload }) {
        let { passwActual, passw, email } = payload;
        try {
                let checkPassw = yield call(signInWithEmailAndPasswordAsync, email, passwActual);
                if (!checkPassw.message) {
                        let success = yield updatePasswordAsync(passw, auth.currentUser);
                        if (!success.message) {
                                yield put(updatePasswordSuccess(false));
                        }
                } else {
                        yield put(updatePasswordSuccess(true));
                }
        } catch (error) {

        }
}
const updatePasswordAsync = async (passw, user) => {
        return await user.updatePassword(passw)
                .then(() => true)
                .catch(error => error);
}
const signInWithEmailAndPasswordAsync = async (email, password) =>
        await auth.signInWithEmailAndPassword(email, password)
                .then(authUser => authUser)
                .catch(error => error);
/* 
update password section END
*/

/* 
Get Categories section START
*/
export function* watchGetCategories() {
        yield takeEvery(GET_CATEGORIES, getCategoriesFirebase);
}
function* getCategoriesFirebase({ payload }) {
        try {
                let categories = yield call(getCategpriesAsync);
                let arr = [];
                if (!categories.message) {
                        categories.forEach(doc => {
                                arr[doc.id] = doc.data();
                        })
                }
                yield put(getCategoriesSuccess(arr));
        } catch (error) {

        }
}
const getCategpriesAsync = async () =>
        await firestore.collection('Categories').get()
                .then(categories => categories)
                .catch(error => error);
/* 
Get Categories section END
*/



export default function* rootSaga() {
        yield all([
                fork(whatchGetProductStates),
                fork(watchGetTestimoniesModule),
                fork(watchGetTestimonies),
                fork(watchGetExperience),
                fork(watchGetEducation),
                fork(watchGetProducts),
                fork(watchSaveProduct),
                fork(watchUpdatePassword),
                fork(watchGetCategories),
                fork(watchUploadFile),
        ]);
}
