import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';

import {firebaseConfig} from '../constants/defaultValues'

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const database = firebase.database();
const firestore = firebase.firestore();
const storage = firebase.storage();
const _firebase = firebase;
export {
   auth,
   database,
   firestore,
   googleProvider,
   facebookProvider,
   storage,
   _firebase
};
