import {
        UPDATE_PASSWORD,
        UPDATE_PASSWORD_SUCCESS,
        GET_CATEGORIES,
        GET_CATEGORIES_SUCCESS,
        UPLOAD_FILE,   
        UPLOAD_FILE_SUCCESS,
        SAVE_PRODUCT_SUCCESS,
        SAVE_PRODUCT,
        GET_PRODUCTS,
        GET_PRODUCTS_SUCCESS,
        PRODUCT_SELECTED,
        GET_EDUCATION,
        GET_EDUCATION_SUCCESS,
        GET_EXPERIENCE,
        GET_EXPERIENCE_SUCCESS,
        GET_TESTIMONIES,
        GET_TESTIMONIES_SUCCESS,
        GET_TESTIMONIES_MODULE,
        GET_TESTIMONIES_MODULE_SUCCESS,
        SET_MODULE_VIDEOURLS,
        SET_MODULE_IMAGES_DOCUMENTS,
        GET_PRODUCT_STATES, 
        GET_PRODUCT_STATES_SUCCESS,
        VIDEOS_READY,
} from "../actions";

const INIT_STATE = {
        loadingUPD: false,
        errorPasswActual: false,
        loadingCategories: false,
        categories: {},
        uploadTask: null,
        storageRef: null,
        saveProductSuccess: false,
        productData: [],
        products: [],
        loadingProducts: false,
        prodSelected: null,
        education: null,
        loadingEducation: false,
        experience: null,
        loadingExperience: false,
        loadingTestimonies: false,
        testimonies: null,
        loadingTestimoniesModule: false,
        testimoniesModule: null,
        videoURLs:[],
        imagesURLs:[],
        productStates:[],
        loadingStates:false,
        newProdID: null,
        _videosReady: [],
};
export default (state = INIT_STATE, action) => {
        switch (action.type) {
                case UPDATE_PASSWORD:
                        return { ...state, loadingUPD: true, errorPasswActual: false };
                case UPDATE_PASSWORD_SUCCESS:
                        return { ...state, loadingUPD: false, errorPasswActual: action.payload };
                case GET_CATEGORIES:
                        return { ...state, loadingCategories: true };
                case GET_CATEGORIES_SUCCESS:
                        return { ...state, loadingCategories: false, categories: action.payload };
                case UPLOAD_FILE:
                        return { ...state };
                case UPLOAD_FILE_SUCCESS:
                        return { ...state, uploadTask: action.payload.uploadTask, storageRef: action.payload.storageRef };
                case SAVE_PRODUCT:
                        return { ...state, saveProductSuccess: false };
                case SAVE_PRODUCT_SUCCESS:
                        return { ...state, saveProductSuccess: true, productData: action.payload };
                case GET_PRODUCTS:
                        return { ...state, loadingProducts: true };
                case GET_PRODUCTS_SUCCESS:
                        return { ...state, loadingProducts: false, products: action.payload };
                case PRODUCT_SELECTED:
                        return { ...state, prodSelected: action.payload };
                case GET_EDUCATION:
                        return { ...state, loadingEducation: true };
                case GET_EDUCATION_SUCCESS:
                        return { ...state, loadingEducation: false, education: action.payload };
                case GET_EXPERIENCE:
                        return { ...state, loadingExperience: true };
                case GET_EXPERIENCE_SUCCESS:
                        return { ...state, loadingExperience: false, experience: action.payload };
                case GET_TESTIMONIES:
                        return { ...state, testimoniesLoading: true };
                case GET_TESTIMONIES_SUCCESS:
                        return { ...state, testimoniesLoading: false, testimonies: action.payload };
                case GET_TESTIMONIES_MODULE:
                        return { ...state, loadingTestimoniesModule: true };
                case GET_TESTIMONIES_MODULE_SUCCESS:
                        return { ...state, loadingTestimoniesModule: false, testimoniesModule: action.payload };
                case SET_MODULE_VIDEOURLS:
                        return { ...state, videoURLs: action.payload };
                case SET_MODULE_IMAGES_DOCUMENTS:
                        return { ...state, imagesURLs: action.payload };
                case GET_PRODUCT_STATES:
                        return { ...state, loadingStates: true };
                case GET_PRODUCT_STATES_SUCCESS:
                        return { ...state, productStates: action.payload, loadingStates: false };
                case VIDEOS_READY:
                      //  console.log('reducer ---->>>>>> ', action.payload, '<<<<<<<<<  ');
                        return { ...state, _videosReady: action.payload };

                //default
                default: return { ...state };
        }
}